"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckboxField = exports.TextareaField = exports.SelectField = exports.TextField = void 0;
const preact_1 = require("preact");
const formik_1 = require("formik");
function TextField(props) {
    return (0, preact_1.h)(formik_1.Field, Object.assign({ className: 'flex-1 w-full pl-4 pr-2 pt-3 pb-2.5 leading-5 bg-white' }, props));
}
exports.TextField = TextField;
function SelectField(props) {
    return (0, preact_1.h)(formik_1.Field, Object.assign({ as: 'select', className: 'flex-1 w-full pl-4 pr-2 pt-3 pb-2.5 leading-5 bg-white bg-select bg-no-repeat bg-[center_right_0.75em]' }, props));
}
exports.SelectField = SelectField;
function TextareaField(props) {
    return (0, preact_1.h)(formik_1.Field, Object.assign({ as: 'textarea', className: 'w-full h-56 resize-none pl-4 pr-2 pt-3 pb-2.5 leading-5 bg-white' }, props));
}
exports.TextareaField = TextareaField;
function CheckboxField(props) {
    return (0, preact_1.h)(formik_1.Field, Object.assign({ type: 'checkbox', className: 'appearance-none w-5 h-5 border-2 border-turquoise-dark bg-white bg-checkbox bg-hidden checked:bg-auto bg-0 rounded focus:ring-4 focus:ring-turquoise-light' }, props));
}
exports.CheckboxField = CheckboxField;
