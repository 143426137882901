"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const preact_1 = require("preact");
const formik_1 = require("formik");
const icons_1 = require("./icons");
function FieldGroup({ children }) {
    return ((0, preact_1.h)("div", { className: 'flex overflow-hidden border-2 border-turquoise-dark bg-white rounded focus-within:ring-4 focus-within:ring-turquoise-light' }, children));
}
exports.default = FieldGroup;
FieldGroup.Status = Status;
function Status({ name, align = 'middle' }) {
    const [, { error, touched }] = (0, formik_1.useField)(name);
    if (!touched) {
        return null;
    }
    return ((0, preact_1.h)("span", { className: `flex-shrink-0 pr-3 ${align === 'middle' ? 'self-center' : 'pt-3.5'}` }, error
        ? (0, preact_1.h)(icons_1.CrossIcon, { className: 'h-4 w-4 fill-error' })
        : (0, preact_1.h)(icons_1.TickIcon, { className: 'h-4 w-4 fill-success' })));
}
