"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const util_1 = require("../lib/util");
class Accordion extends HTMLUListElement {
    constructor() {
        super();
        this.buttons = [];
        this.panels = [];
        this.handleClick = this.handleClick.bind(this);
    }
    connectedCallback() {
        this.buttons = Array.from(this.querySelectorAll('button[data-panel-id]'));
        this.panels = this.buttons.map((button) => (0, util_1.getElementByDataAttribute)(button, 'panelId'));
        this.hideAll();
        this.buttons.forEach((button, index) => {
            button.disabled = false;
            button.setAttribute('aria-controls', this.panels[index].id);
            button.addEventListener('click', this.handleClick);
        });
        if (window.location.hash) {
            const index = Array.from(this.children).findIndex((child) => {
                return child.id === window.location.hash.slice(1);
            });
            this.show(index, false);
        }
    }
    disconnectedCallback() {
        this.buttons.forEach((button) => {
            button.disabled = true;
            button.removeAttribute('aria-controls');
            button.removeEventListener('click', this.handleClick);
        });
        this.panels.forEach((panel) => panel.hidden = false);
    }
    handleClick(event) {
        const index = this.buttons.indexOf(event.target);
        const button = this.buttons[index];
        if (button.getAttribute('aria-expanded') === 'true') {
            this.hide(index);
        }
        else {
            this.show(index);
        }
    }
    update(index, shown) {
        const button = this.buttons[index];
        const panel = this.panels[index];
        button.setAttribute('aria-expanded', `${shown}`);
        panel.hidden = !shown;
        if (shown) {
            const parent = button.closest('li');
            if (parent) {
                history.replaceState(null, '', `#${parent.id}`);
            }
        }
    }
    show(index, scroll = true) {
        this.update(index, true);
        if (scroll) {
            this.buttons[index].scrollIntoView({
                behavior: 'smooth',
            });
        }
    }
    hide(index) {
        this.update(index, false);
    }
    hideAll() {
        for (let i = 0; i < this.buttons.length; i++) {
            this.hide(i);
        }
    }
}
exports.default = Accordion;
