"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const preact_1 = require("preact");
const formik_1 = require("formik");
function LoadingOverlay() {
    const { isSubmitting } = (0, formik_1.useFormikContext)();
    if (!isSubmitting) {
        return null;
    }
    return ((0, preact_1.h)("div", { className: 'absolute inset-0 z-10 flex items-center justify-center' },
        (0, preact_1.h)(LoadingIcon, null)));
}
exports.default = LoadingOverlay;
function LoadingIcon() {
    return ((0, preact_1.h)("svg", { viewBox: '0 0 44 44', className: 'w-20 stroke-turquoise-dark' },
        (0, preact_1.h)("g", { fill: 'none', fillRule: 'evenodd', strokeWidth: 2 },
            (0, preact_1.h)("circle", { cx: 22, cy: 22, r: 1 },
                (0, preact_1.h)("animate", { attributeName: 'r', begin: '0s', dur: '1.8s', values: '1; 20', calcMode: 'spline', keyTimes: '0; 1', keySplines: '0.165, 0.84, 0.44, 1', repeatCount: 'indefinite' }),
                (0, preact_1.h)("animate", { attributeName: 'stroke-opacity', begin: '0s', dur: '1.8s', values: '1; 0', calcMode: 'spline', keyTimes: '0; 1', keySplines: '0.3, 0.61, 0.355, 1', repeatCount: 'indefinite' })),
            (0, preact_1.h)("circle", { cx: 22, cy: 22, r: 1 },
                (0, preact_1.h)("animate", { attributeName: 'r', begin: '-0.9s', dur: '1.8s', values: '1; 20', calcMode: 'spline', keyTimes: '0; 1', keySplines: '0.165, 0.84, 0.44, 1', repeatCount: 'indefinite' }),
                (0, preact_1.h)("animate", { attributeName: 'stroke-opacity', begin: '-0.9s', dur: '1.8s', values: '1; 0', calcMode: 'spline', keyTimes: '0; 1', keySplines: '0.3, 0.61, 0.355, 1', repeatCount: 'indefinite' })))));
}
